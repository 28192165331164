ž<template>
    <div>
        <app-page-header v-if="!showLoadingWindow"></app-page-header>
        <v-container grid-list-xl>
            <replace-parking-permit v-if="computePermitType == 1" :uuid="uuid" :permitId="computedPermitId" :permit="computedPermit"></replace-parking-permit>
            <replace-promenada-permit v-if="computePermitType == 2" :uuid="uuid" :permitId="computedPermitId" :permit="computedPermit"></replace-promenada-permit>
        </v-container>
    </div>
</template>

<script>
import { API_ENDPOINT } from '@/helpers/api'
import axios from 'axios'
import { EventBus } from '@/main.js'
const ReplaceParkingPermit = () => import('@/components/user/ReplaceParkingPermit.vue')
const ReplacePromenadaPermit = () => import('@/components/user/ReplacePromenadaPermit.vue')


export default {
    components: {
        ReplaceParkingPermit,
        ReplacePromenadaPermit
    },

    data: () => ({
        uuid: null,
        permitId: '',
        permit: null,
        permitType: null,
        logo: "https://cloud.conforma.si/public/img/obcina-bled/grb_bled_135_135.png",
    }),

    computed: {
        showLoadingWindow() {
            if(this.loading) {
                return true;
            }

            return false;
        },

        computedPermitId() {
            return this.permitId
        },

        computedPermit() {
            return this.permit
        },

        computePermitType()
        {
            return this.permitType
        }
    },

    methods: {
        getPermitData(uuid) {
            this.$store.commit('SET_LOADER_TEXT', 'Pridobivanje podatkov o dovolilnici je v teku...')
            this.$store.commit('SET_LOADER', true)
            var vm = this
            this.$store.dispatch("USER_EDIT_PERMIT", uuid)
            .then(response => {
                //window.console.log(response.data.data)
                /*vm.permit = response.data.data
                vm.permitId = response.data.data.id;
                vm.user_type = response.data.data.user_type.uuid;
                vm.name = response.data.data.name
                vm.address = response.data.data.address
                vm.town = response.data.data.town
                vm.postal_code = response.data.data.postal_code
                vm.residence_type = response.data.data.residence_type.uuid
                vm.phone_number = response.data.data.phone_number
                vm.email = response.data.data.email
                vm.emso = response.data.data.emso
                vm.tax_number = response.data.data.tax_number
                vm.entity = response.data.data.entity_type.uuid,
                vm.registration_plate_number_1 = response.data.data.registration_plate_number_1
                vm.vehicle_ownership = response.data.data.vehicle_ownership*/
                vm.permitType = (response.data.data.permit_type != null) ? response.data.data.permit_type.type : null
                vm.permitId = response.data.data.id
                vm.permit = response.data.data
            })
            .catch(error => {
                window.console.log(error)
            })
            .finally(() => {
                setTimeout(() => { 
                    vm.loading = false
                    this.$store.commit('SET_LOADER', false) 
                }, 400)
            })
        },

        submitForm() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
            let vm = this
            if(this.$refs.form.validate()) {
                /*let one  = 1;
                if(1 == one){*/
                vm.$store.commit('SET_LOADER_TEXT', 'Oddaja vloge je v teku...')
                vm.$store.commit('SET_LOADER', true)
                let payload = new FormData();
                payload.append('user_type', vm.user_type)
                payload.append('name', vm.name)
                payload.append('address', vm.address)
                payload.append('town', vm.town)
                payload.append('postal_code', vm.postal_code)
                payload.append('residence_type', vm.residence_type)
                payload.append('phone_number', vm.phone_number)
                payload.append('email', vm.email)
                payload.append('emso', vm.emso)
                payload.append('tax_number', vm.tax_number)
                payload.append('entity', vm.entity)
                payload.append('registration_plate_number_1', vm.registration_plate_number_1)
                payload.append('vehicle_ownership', vm.vehicle_ownership)  

                let c = 1;
                vm.files_data.map(file => {
                    payload.append('file_' + c, file.file)
                    c++
                });
                
                //window.console.log(payload)
                vm.loading = true
                axios({
                    method: 'POST',
                    url: API_ENDPOINT + 'v1/user/applications/parking/create',
                    data: payload,
                    headers: {
                        'Authorization': `Bearer ${vm.$store.state.user.access_token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    //window.console.log(response)
                    vm.clearForm()
                    vm.backendMessages.push('Vloga je bila uspešno oddana. Vaša vloga bo obravana v najkrajšem možnem času. O nadaljnjem poteku v zvezi z izdajo dovolilnice vas bomo obvestili.')
                        vm.$vuetify.goTo('#form_card', {
                        duration: 500,
                        offset: 0,
                        easing: 'easeInOutCubic'
                    })
                })
                .catch(error => {
                    window.console.log(error)
                    window.console.log(error.response)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)

                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })

                            vm.$vuetify.goTo('#form_card', {
                                duration: 500,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }
                    }

                })
                .finally(() => {
                    setTimeout(() => {
                        vm.$store.commit('SET_LOADER', false)
                        vm.$store.commit('SET_LOADER_TEXT', '')
                    }, 600)
                    vm.loading = false
                    window.console.log("finally! :)");
                })
            } 
            else {
                this.$vuetify.goTo('#form_card', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
                window.console.log("Validation ERROR");
            }
            
        },

        clearForm() {
            //this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.user_type = null
            this.name = null
            this.address = null
            this.town = null
            this.postal_code = null
            this.residence_type = null
            this.phone_number = null
            this.email = null
            this.emso = null
            this.entity = null
            this.registration_plate_number_1 = null
            this.vehicle_ownership = null
            this.files_data = [];
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
            setTimeout(() =>{
                this.$vuetify.goTo('#form_card', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            }, 200)
        },
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
    },

    mounted() {
        var vm = this
        this.uuid = this.$route.params.id
//        this.getResources()
        if(this.uuid != null) {
            this.getPermitData(this.uuid)
        }

        EventBus.$on('USER_GET_PERMIT_DATA', function(data){
            if(data != null && data != undefined) {
                vm.$store.dispatch("USER_EDIT_PERMIT", vm.uuid)
                .then(response => {
                    vm.permitType = (response.data.data.permit_type != null) ? response.data.data.permit_type.type : null
                    vm.permitId = response.data.data.id
                    vm.permit = response.data.data
                    setTimeout(() => {
                        EventBus.$emit('USER_REFRESH_PERMIT_DATA', {
                            success: true
                        })
                    }, 200)
                })
                .catch(error => {
                    window.console.error("### ERROR PermitReplacement@refreshPermitData ###")
                    window.console.log(error)
                    EventBus.$emit('USER_REFRESH_PERMIT_DATA', {
                        success: false
                    })
                })
            } else {
                setTimeout(() => {
                    EventBus.$emit('USER_REFRESH_PERMIT_DATA', {
                        success: false
                    })
                }, 200)
            }
        })
    },

    destroyed() {
        EventBus.$off('USER_GET_PERMIT_DATA')
    }
}

</script>

<style scoped>

</style>